import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/hospitalizationConclusions',
  	  method: 'get',
        params
  	})
  }

  export function indexitems(params) {
    return request({
      url: `/hospitalizationConclusions/indexitems/${params.patient_id}`,
      method: 'GET',
      params
    });
  }

export function show(id) {
    return request({
      url: `/hospitalizationConclusions/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/hospitalizationConclusions',
      method: 'post',
      data
    })
  }

  export function update(datas) {
    let data = datas.data
    let id = datas.id
    return request({
      url: `/hospitalizationConclusions/${id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/hospitalizationConclusions/${id}`,
      method: 'delete',
    })
  }

  export function print(params) {
    return request({
        url: `/hospitalizationConclusions/print`,
        method: 'get',
        params
    })
}

export function printCenter(params) {
  return request({
    url: '/hospitalizationConclusions/print-center',
    method: 'get',
  params
  })
}

export function patientConculation(params) {
  return request({
    url: '/hospitalizationConclusions/patient-conculation',
    method: 'get',
  params
  })
}
