import request from './../utils/request'

export function index(params) {
  return request({
    url: '/services',
    method: 'get',
    params
  })
}

export function indexLaboratory(params) {
  return request({
    url: '/servicesLab/indexLaboratory',
    method: 'get',
    params
  })
}

export function color(params) {
  return request({
    url: '/services/color',
    method: 'get',
    params
  })
}

export function inventory(params) {
  return request({
    url: '/services/inventory',
    method: 'get',
    params
  })
}

export function inventoryOnlyServiceName(params) {
  return request({
    url: '/services/inventoryOnlyServiceName',
    method: 'get',
    params
  })
}

export function parentInventory(params) {
  return request({
    url: '/services/parentInventory',
    method: 'get',
    params
  })
}

export function relationInventory(params) {
  return request({
    url: '/services/relationInventory',
    method: 'get',
    params
  })
}

export function relationFreeInventory(params) {
  return request({
    url: '/services/relationFreeInventory',
    method: 'get',
    params
  })
}

export function relationInventoryOrder(params) {
  return request({
    url: '/services/relationInventoryOrder',
    method: 'get',
    params
  })
}

export function chart(params) {
  return request({
    url: '/services/chart',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/services/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/services',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/services/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/services/${id}`,
    method: 'delete',
  })
}

