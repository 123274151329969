import {show, index, acceptProd,reportProduct,checkProductAvailability} from '../../../api/warehouseProduct';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          index(params)
            .then(response => {
              commit('SET_LIST', response.data.data.warehouseProducts.data);
              commit('UPDATE_PAGINATION', {
                key: 'total',
                value: response.data.data.warehouseProducts.pagination.total
              });
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },

    acceptProd({commit}, param = {}) {
        return new Promise((resolve,reject) => {
            acceptProd(param).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    reportProduct({commit}, param = {}) {
        return new Promise((resolve,reject) => {
            reportProduct(param).then(res => {
                resolve(res.data.data.warehouseProduct.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    checkProductAvailability({commit}, param = {}) {
        return new Promise((resolve,reject) => {
            checkProductAvailability(param).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({commit}, params = {}) {
        return new Promise((resolve,reject) => {
            show(params).then(res => {
                commit("SET_MODEL",res.data.data.warehouseProduct);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
