<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Номер Амбулаторной карты'"
    filterable
    clearable
  >
    <el-option v-for="item in hospitalCodes" :key="item.id" :label="item.number" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['hospitalPatientId'], 
  watch: {
    selected: {
       handler: function(val, oldval){
        if (val) {
            this.selectedNumber({history_id: val})          
        }
      },
      deep: true,
    },
    lastHistory: {
      handler: function() {
        if (this.lastHistory) {
          this.selected = this.lastHistory.id;
          this.$emit("input", this.selected);          
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
        lastHistory: 'dailyTreatments/lastHistory',
        hospitalCodes: 'patientHistories/inventory',
    }),
  },
  
  mounted() {    
    // this.selectedNumber({history_id: this.selected});
    // console.log('mon Hosp');
    this.getInventory({patient_id: this.hospitalPatientId})
    .then(res => {
         if (this.lastHistory) {
           
          this.selected = this.lastHistory.id;
          this.$emit("input", this.selected);          
        }
    });
  },
  methods: {
    ...mapActions({
      getInventory: 'patientHistories/inventory',
      selectedNumber: 'dailyTreatments/getSelectedNumber',
    })
  },
};
</script>
